import { useState, useContext, useEffect } from "preact/hooks";
import { createContext, Component } from "preact";
import { SessionContext } from "../SessionContext.tsx";
import { ProgressiveRequest } from "../ProgressiveRequest.tsx";
import { getServer } from '../Server.tsx';
import IconChevronLeft from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/chevron-left.tsx";
import IconBook2 from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/book-2.tsx";

export function MarketingContent({ back, args }){

	return (
		<div>
			<_MarketingContent args={args} back={back} />
		</div>
	);
}

class _MarketingContent extends Component {
	static contextType = SessionContext;
	
	state = {
		loading: true,
		items: null
	};

	componentDidMount() {
		let that = this;

		this.load();
	}

	isLoading = false;
	load(){
		let that = this;
		let session = this.context;

		if(this.isLoading){
			return;
		}

		this.isLoading = true;
		ProgressiveRequest({
			url: getServer()+'/api/front/marketing',
			method: 'post',
			data: {
				action: 'content',
				session: session
			},
			receiving: function(data){

				console.log('receiving');
				console.log(data);

				that.setState({
					items: data.items
				});

			},
			received: function(){
				console.log('done');
				that.isLoading = false;
			}
		});
	}

	displayContent = function(item){
		this.setState({content: item});
	}
	
	render() {
		let that = this;

		if(this.state.items && this.state.items.services.data){
			
			return (
				<div>
					<div class="d-flex align-items-center mt-2 mb-4">
						{that.props.back?
							<div class="p-1 rounded-pill text-white me-4 arrowb" onClick={that.props.back}>
								<IconChevronLeft class="w-6 h-6" />
							</div>:
							<a class="p-1 rounded-pill text-white me-4 arrowb" href="/">
								<IconChevronLeft class="w-6 h-6" />
							</a>
						}
						<h1 class="d-flex align-items-center h3 mb-0">
							<IconBook2 class="w-6 h-6" /><span class="ms-2">Le catalogue</span>
						</h1>
					</div>
					<div class="my-4">
						<div class="card" style="background-color:rgb(235 236 240)">
							<div class="card-body">
								<div class="h3 titleb">Développez votre business</div>
								<div>Découvrez les nouveaux services qui vont vous aider au quotidien dans votre activité.</div>
							</div>
						</div>
					</div>
					<div class="">
						{
							this.state.items.services.data.list.map((service)=>{
								return <div class="my-2">
									<div class="card shadow">
										<div class="card-body">
											<div>
												<div class="mb-2">
													<div class="h4 titleb">
														<img src="icons/Transactions_Historique_Paiements_Facture.svg" class="d-inline-block me-1" style="width: 20px;" />
														{service.name}
													</div>
													<div class="mt-2 h5">{service.description}</div>
													<div class="mt-2 mb-3">{service.content}</div>
													<div class="d-flex justify-content-between">
														<a type="button" href="#" style="background: rgb(41, 45, 135);" class="btn text-white border-0 rounded-pill d-flex align-items-center btn-sm ps-4 pe-3" onClick={()=>{alert(service.id+' '+service.name);}}>{service.buttonLabel} <i class="fa-regular fa-circle-down ms-2"></i></a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>;
							})
						}
					</div>
				</div>
			);

		}

		return <div>
			<div class="spinner-border text-secondary" role="status">
				<span class="visually-hidden">Loading...</span>
			</div>
		</div>
	}
}